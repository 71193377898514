import React, { FC } from 'react'
import desktopBackgroundImage from '../../images/services/banner-assurances-desktop.jpg'
import mobileBackgroundImage from '../../images/services/banner-assurances-mobile.jpg'
import { Colors } from '../../colors'
import { ServiceDetail } from '../../components/service-detail/service-detail'
import { AvailableServices } from '../../available-services'
import ServicePageLayout from '../../components/layouts/service-page-layout'

export const AssuranceDePersonnes: FC = () => (
    <ServicePageLayout
        primaryColor={Colors.orange}
        title="Assurance de personnes"
        desktopBanner={desktopBackgroundImage}
        mobileBanner={mobileBackgroundImage}
        bannerText="Assurance de personnes. Soyez prêt face aux imprévus de la vie"
        serviceBrief={`Plusieurs options s’offrent à vous pour veiller sur votre sécurité financière et celle de vos proches s’il devait malheureusement vous arriver quelque chose. Ce ne sont donc pas vos biens matériels qui sont couverts, mais plutôt vous et votre capacité à générer des revenus stables.`}
        serviceToHide={AvailableServices.assurance}
    >
        <>
            <ServiceDetail
                color={Colors.turquoise}
                title="Assurance vie"
                details="Pour minimiser les répercussions financières qui découlent d’un décès et protéger les personnes qui vous sont chères."
            />
            <ServiceDetail
                color={Colors.skyBlue}
                title="Assurance médicament complémentaire"
                details="Pour combler une partie ou la totalité des frais pour les médicaments que votre régime d’assurance ne couvre pas."
            />
            <ServiceDetail
                color={Colors.yellow}
                title="Assurance santé et invalidité"
                details="Pour vous consacrer à votre rétablissement sans le stress des conséquences financières."
            />
            <ServiceDetail
                color={Colors.purple}
                title="Assurance hypothécaire"
                details={`Pour vous assurer de conserver votre propriété en cas de diagnostic de cancer, de décès ou d'invalidité.`}
            />
        </>
    </ServicePageLayout>
)

export default AssuranceDePersonnes
